import { useState, ReactNode, createContext, useContext, Dispatch, SetStateAction } from 'react';

interface SideNavigationContextType {
  isExpanded: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
}

const SideNavigationContext = createContext<SideNavigationContextType | null>(null);
export const SideNavigationContextProvider = ({ children }: { children: ReactNode }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <SideNavigationContext.Provider value={{ isExpanded, setIsExpanded }}>{children}</SideNavigationContext.Provider>
  );
};

export const useSideNavigation = () => {
  const context = useContext(SideNavigationContext);
  if (!context) {
    throw new Error('useSideNavigation must be used within a SideNavigationContext');
  }
  return context;
};
