import { useWindowSize } from 'usehooks-ts';

import { ReactComponent as ThumbUp } from '../../../assets/icons/thumb-up.svg';
import { ReactComponent as ThumbDown } from '../../../assets/icons/thumb-down.svg';

import { TrainingVideo } from '../../../types/types';
import { useState } from 'react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

interface VideoPlayerProps {
  video?: TrainingVideo;
}

const VideoPlayer = ({ video }: VideoPlayerProps) => {
  const [liked, setLiked] = useState(false);
  const [unliked, setUnliked] = useState(false);

  const { height: windowHeight, width: windowWidth } = useWindowSize();

  // TODO: define what to do when like/dislike is clicked

  const handleLikeClick = () => {
    setLiked((v) => !v);
    setUnliked(false);
  };

  const handleUnlikeClick = () => {
    setUnliked((v) => !v);
    setLiked(false);
  };

  return (
    <div className="z-50 flex aspect-video min-w-[50vw] flex-1 flex-col justify-between">
      <h2 className="p-5 text-2xl font-bold text-primary-800">{video?.name}</h2>
      <div className="self-center">
        <video
          src={video?.trainingVideoURL || ''}
          controls
          width={windowWidth * 0.7}
          height={windowHeight * 0.7}
          className="aspect-video"
          autoPlay
          muted={false}
        />
      </div>
      <div className="flex items-center justify-between p-3">
        <p className="font-normal text-gray-600">Did you find this video helpful?</p>
        <div className="flex gap-2">
          <ThumbUp
            className={twMerge(
              classNames('h-6 w-6 cursor-pointer fill-gray-100 transition-transform hover:scale-110', {
                'fill-primary-500': liked,
              }),
            )}
            onClick={handleLikeClick}
          />
          <ThumbDown
            className={twMerge(
              classNames('h-6 w-6 cursor-pointer fill-gray-100 transition-transform hover:scale-110', {
                'fill-error-500': unliked,
              }),
            )}
            onClick={handleUnlikeClick}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
