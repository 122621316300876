import { getHeaders, host } from '.';
import { User } from "../types/types";

export const fetchAllUsers = async () => {
  const headers = await getHeaders();
  
  try {
    return fetch(`${host}/users`, {
      headers,
      method: 'GET',
    })
      .then((res) => res.json())
      .catch((e) => console.log('Error fetching users: ', e));
  } catch (e) {
    console.log('Error fetching users: ', e);
  }
};

export const fetchAllUsersPaged = async (page = 0, sortBy?: string | null, filter?: string | null, size = 15) => {
  const searchParams = new URLSearchParams();
  const headers = await getHeaders();

  if (page) {
    searchParams.append('page', page.toString());
  }
  if (sortBy) {
    searchParams.append('sort', sortBy);
  }
  if (size) {
    searchParams.append('size', size.toString());
  }
  if (filter) {
    searchParams.append('lastNamePrefix', filter);
    searchParams.append('firstNamePrefix', filter);
    searchParams.append('emailPrefix', filter);
    searchParams.append('workEmailPrefix', filter);
  }
  
  try {
    return fetch(`${host}/users/filters?${searchParams.toString()}`, {
      headers,
      method: 'GET',
    })
      .then((res) => res.json())
      .catch((e) => console.log('Error fetching users: ', e));
  } catch (e) {
    console.log('Error fetching users: ', e);
  }
};

export const fetchUserById = async (id?: number) => {
  const headers = await getHeaders();

  try {
    return fetch(`${host}/users/${id}`, {
      headers,
      method: 'GET',
    })
      .then((res) => res.json())
      .catch((e) => console.log('Error fetching user: ', e));
  } catch (error) {
    console.log('Error fetching user: ', error);
  }
};

export const loginUser = async () => {
  const headers = await getHeaders();

  try {
    return fetch(`${host}/users/login`, {
      headers,
      method: 'PATCH',
    })
      .then((res) => res.json())
      .catch((e) => console.log('Error fetching user:', e));
  } catch (error) {
    console.log('Error fetching user: ', error);
  }
};

export const updateUserById = async (payload: User) => {
  const headers = await getHeaders();

  try {
    return fetch(`${host}/users/${payload.userId}`, {
      headers,
      method: 'PUT',
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((e) => console.log('Error updating user: ', e));
  } catch (e) {
    console.log('Error updating user: ', e);
  }
};

export const createUser = async (p: User) => {
  const headers = await getHeaders();

  try {
    return fetch(`${host}/users`, {
      headers,
      method: 'POST',
      body: JSON.stringify(p),
    })
      .then((res) => res.json())
      .catch((e) => console.log('Error creating user: ', e));
  } catch (e) {
    console.log('Error creating user: ', e);
  }
};

export const disableUserById = async (id: number) => {
  const headers = await getHeaders();

  try {
    return fetch(`${host}/users/${id}/disable`, {
      headers,
      method: 'PATCH',
    })
      .then((res) => res.json())
      .catch((e) => console.log('Error disabling user: ', e));
  } catch (e) {
    console.log('Error disabling user: ', e);
  }
};

export const resetMFA = async (id: number) => {
  const headers = await getHeaders();

  try {
    return fetch(`${host}/users/${id}/resetMFA`, {
      headers,
      method: 'PATCH',
    })
      .then((res) => res.json())
      .catch((e) => console.log('Error Resetting MFA for user: ', e));
  } catch (e) {
    console.log('Error Resetting MFA for user: ', e);
  }
};

export const activateUserById = async (id: number) => {
  const headers = await getHeaders();

  try {
    return fetch(`${host}/users/${id}/enable`, {
      headers,
      method: 'PATCH',
    })
      .then((res) => res.json())
      .catch((e) => console.log('Error activating user: ', e));
  } catch (e) {
    console.log('Error activating user: ', e);
  }
};

export const searchUsers = async (
  firstName = '',
  lastName = '',
  email = '',
  roleTypes = 'USER,EC,VIEWER,ADMIN',
  status = 'INVITED,FORCE_UPDATE,ACTIVE',
) => {
  const headers = await getHeaders();

  try {
    return fetch(
      `${host}/users/search?firstName=${firstName}&lastName=${lastName}&email=${email}&status=${status}&roleTypes=${roleTypes}&anyPosition=true&sort=firstName,asc&page=0&size=100`,
      {
        headers,
        method: 'GET',
      },
    )
      .then((res) => res.json())
      .catch((e) => console.log('Error searching users: ', e));
  } catch (e) {
    console.log('Error searching users: ', e);
  }
};

export const resendInvite = async (id: number) => {
  const headers = await getHeaders();

  try {
    return fetch(`${host}/users/${id}/resend`, {
      headers,
      method: 'PATCH',
    })
      .then((res) => res.json())
      .catch((e) => console.log('Error sending invite.', e));
  } catch (e) {
    console.log('Error sending invite: ', e);
  }
};
