import { Auth } from '@aws-amplify/auth';

import {
  fetchAllUsers,
  fetchAllUsersPaged,
  fetchUserById,
  loginUser,
  updateUserById,
  createUser,
  disableUserById,
  resetMFA,
  activateUserById,
  searchUsers,
  resendInvite,
} from './users';

import { fetchAccounts, createAccount, updateAccount } from './accounts';

import { fetchAllRoles } from './roles';

import { fetchAssetById, fetchAssetsByAccountId, fetchBulkTemplate, bulkAssetsUpload } from './assets';

import {
  getActiveInventory,
  fetchInventory,
  fetchInventoryAssets,
  addInventory,
  searchInventory,
  updateInventoryControl,
  inviteUsersToInventoryControl,
  uninviteUsersToInventoryControl,
  completeInventoryControl,
  updateInventoryControlName,
} from './inventory';

export const getHeaders = async () => {
  const auth = await Auth.currentSession();
  const accessToken = auth.getAccessToken();
  const jwt = accessToken.getJwtToken();

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${jwt}`);
  return headers;
};

export const host = process.env.REACT_APP_API_URL;

export {
  activateUserById,
  addInventory,
  completeInventoryControl,
  createAccount,
  createUser,
  disableUserById,
  fetchAccounts,
  fetchAllRoles,
  fetchAllUsers,
  fetchAllUsersPaged,
  fetchAssetById,
  fetchAssetsByAccountId,
  fetchBulkTemplate,
  fetchInventory,
  fetchInventoryAssets,
  fetchUserById,
  getActiveInventory,
  bulkAssetsUpload,
  inviteUsersToInventoryControl,
  loginUser,
  resendInvite,
  resetMFA,
  searchInventory,
  searchUsers,
  uninviteUsersToInventoryControl,
  updateInventoryControlName,
  updateAccount,
  updateInventoryControl,
  updateUserById,
};
