import { useMediaQuery } from 'usehooks-ts';

type BreakPointKey = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

const breakpoints: Record<BreakPointKey, number> = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

export const useGreater = (k: BreakPointKey) => {
  return useMediaQuery(`(min-width: ${breakpoints[k]}px)`);
};

export const useSmaller = (k: BreakPointKey) => {
  return useMediaQuery(`(max-width: ${breakpoints[k]}px)`);
};

export const useBetween = (a: BreakPointKey, b: BreakPointKey) => {
  return useMediaQuery(`(min-width: ${breakpoints[a]}px) and (max-width: ${breakpoints[b]}px)`);
};
