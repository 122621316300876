import React, { useState, useContext, createContext } from 'react';
export const AppContext = createContext();

function AppContextProvider({ children }) {
  const [pageTitle, setPageTitle] = useState('');
  const value = { pageTitle, setPageTitle };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

function useAppContext() {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('appContext must be used within a AppContext');
  }
  return context;
}
export { AppContextProvider, useAppContext };
