import { KeyboardArrowLeft } from '@mui/icons-material';
import React from 'react';

interface ChipButtonProps {
  showChevronBack?: boolean;
  text: string;
  onClick?: () => void;
}
const ChipButton = ({ showChevronBack = false, text, onClick = () => {} }: ChipButtonProps) => {
  return (
    <button
      onClick={onClick}
      className="flex w-max items-center gap-2 rounded-full bg-primary-500/25 px-3 text-xs font-bold text-primary-500"
    >
      {showChevronBack && <KeyboardArrowLeft className="-mx-2" />}
      <span>{text}</span>
    </button>
  );
};

export default ChipButton;
