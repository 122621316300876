import { ComponentType, SVGProps } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import { ReactComponent as Inventory } from '../../assets/icons/inventory.svg';
import { ReactComponent as Category } from '../../assets/icons/category.svg';
import { ReactComponent as BarcodeScanner } from '../../assets/icons/barcode-scanner.svg';
import { ReactComponent as ManageAccounts } from '../../assets/icons/manage_accounts.svg';
import { ReactComponent as Apartment } from '../../assets/icons/apartment.svg';

import { useSideNavigation } from '../../contexts/SideNavigationContext';
import routes from '../../constants/routes';
import { useGreater } from '../../hooks/useBreakpoints';
import { useUserContext } from '../../contexts/UserContext';

const Navigation = () => {
  const { isExpanded } = useSideNavigation();
  const { canUseWebApp } = useUserContext();

  return (
    <nav
      className={classNames('m-0 flex flex-1 flex-col items-start gap-y-5 overflow-hidden  p-0', {
        'w-full': !isExpanded,
      })}
    >
      {canUseWebApp ? (
        <>
          <NavItem Component={Inventory} path={routes.dashboard} label="Dashboard" />
          <NavItem Component={Category} path={routes.assets} label="Assets" />
          <NavItem Component={BarcodeScanner} path={routes.inventory} label="Inventory" />
          <NavItem Component={ManageAccounts} path={routes.users} label="Users" />
          <NavItem Component={Apartment} path={routes.accounts} label="Manage Accounts" />
        </>
      ) : null}
    </nav>
  );
};

interface NavItemProps {
  Component: ComponentType<SVGProps<SVGSVGElement>>;
  label?: string;
  path: string;
}

export const NavItem = ({ Component, label, path }: NavItemProps) => {
  const { isExpanded, setIsExpanded } = useSideNavigation();
  const { pathname } = useLocation();
  const isDesktop = useGreater('lg');
  const active = pathname.startsWith(path);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
    if (!isDesktop) {
      setIsExpanded(false);
    }
  };
  return (
    <div
      className="group flex cursor-pointer flex-row items-center gap-x-2 overflow-hidden text-clip whitespace-nowrap"
      onClick={handleClick}
    >
      <div
        className={classNames(
          '-z-10 m-auto grid h-8 min-w-[48px] place-content-center rounded-3xl py-4 transition-colors',
          {
            'bg-primary-200': active,
            'bg-transparent': !active,
          },
        )}
      >
        <Component
          className={classNames('z-10 !w-12 object-contain', {
            'fill-primary-500': active,
            'fill-primary-800 group-hover:fill-primary-500': !active,
          })}
        />
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, delay: 0.1 }}
            className={classNames('text-xs font-bold group-hover:text-primary-500', {
              'text-primary-800': !active,
              'text-primary-500': active,
            })}
          >
            {label}
          </motion.span>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Navigation;
