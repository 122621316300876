import { Auth } from '@aws-amplify/auth';
import axios from 'axios';
import { LAST_LOGIN_TIMESTAMP } from "../constants";
import dayjs from "dayjs";
import { checkIfSessionExpired } from "../utils/helpers";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(async (config) => {
  const currentUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();
  
  const isExpired = checkIfSessionExpired();
  
  let jwt = currentSession.getAccessToken().getJwtToken();
  
  if (!isExpired) {
    localStorage.setItem(LAST_LOGIN_TIMESTAMP, dayjs().toISOString());
    let refreshSessionPromise = new Promise<string>((resolve, reject) => {
      currentUser.refreshSession(currentSession.getRefreshToken(), (err: any, result: any) => {
        if (err || !result?.accessToken?.jwtToken) {
          reject(err);
        }

        resolve(result.accessToken.jwtToken);
      });
    });
    
    jwt = await refreshSessionPromise;
  }
  
  config.headers.Authorization = `Bearer ${jwt}`;

  return config;
});

export default api;
