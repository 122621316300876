const routes = {
  root: '/',
  dashboard: '/dashboard',
  reports: '/reports',
  assets: '/assets',
  accounts: '/accounts',
  users: '/users',
  setup: '/setup',
  contact: '/contact',
  locations: '/locations',
  docs: '/docs',
  inventory: '/inventory',
  login: '/login',
  signUp: '/sign-up',
  setupMFA: '/setup-mfa',
  MFA: '/mfa',
  resetPassword: '/reset-password',
  newPassword: '/new-password',
  legal: '/legal',
  privacy: '/privacy',
  terms: '/terms',
  tutorials: '/tutorials',
  releaseNotes: '/release-notes',
  userLanding: '/landing',
};

export default routes;
