import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import '../css/App.css';

import CssBaseline from '@mui/material/CssBaseline';
import { Outlet, useNavigate } from 'react-router-dom';
import routes from '../constants/routes';
import { Navigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import MenuIcon from '@mui/icons-material/Menu';

import { useUserContext } from '../contexts/UserContext';
import { SideNavigationContextProvider, useSideNavigation } from '../contexts/SideNavigationContext';
import { useGreater } from '../hooks/useBreakpoints';

import { OldHeader } from '../components/OldHeader/OldHeader';
import SideNavigation from '../components/SideNavigation';
import { Suspense, useEffect } from 'react';
import Spinner from '../components/Spinner';
import { LAST_LOGIN_TIMESTAMP } from '../constants';
import dayjs from 'dayjs';
import { checkIfSessionExpired } from "../utils/helpers";

interface LayoutProps {
  oldHeader?: boolean;
}

export const Layout = ({ oldHeader = false }: LayoutProps) => {
  const { userInfo } = useUserContext();
  const location = useLocation();
  const isDesktop = useGreater('lg');
  const navigate = useNavigate();

  useEffect(() => {
    const isExpired = checkIfSessionExpired();
    if (isExpired) {
      localStorage.clear();
      sessionStorage.clear();
      navigate(`${routes.login}/${location.search}`, {
        state: {
          location,
          message: {
            title: 'Logged out!',
            body: `You've been logged out. Please re-login.`,
            variant: 'error',
          },
        },
      });
    }
  }, [location, navigate]);

  if (!userInfo?.user?.userId) {
    return <Navigate to={`${routes.login}/${location.search}`} replace state={{ location }} />;
  }

  return (
    <SideNavigationContextProvider>
      <CssBaseline />
      <div className="flex min-h-screen w-screen">
        <HamburgerButton />

        <SideNavigation />

        <div
          className={classNames('flex h-screen flex-1 flex-col items-stretch bg-gray-80', {
            'ml-20': isDesktop,
          })}
        >
          {oldHeader && <OldHeader />}
          <div
            className={classNames('flex flex-1 overflow-scroll', {
              'p-4': oldHeader,
            })}
          >
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </SideNavigationContextProvider>
  );
};

const HamburgerButton = () => {
  const { setIsExpanded } = useSideNavigation();

  return (
    <button
      className={classNames('absolute left-0 top-0 z-20 h-10 w-10 lg:hidden')}
      onClick={() => {
        setIsExpanded(true);
      }}
    >
      <MenuIcon />
    </button>
  );
};

const Loading = () => {
  return (
    <div className="flex flex-1 items-center justify-center">
      <Spinner size={100} />
    </div>
  );
};
