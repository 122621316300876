import React, { useState, useContext, createContext, useEffect, Dispatch, useCallback } from 'react';
import { fetchAccounts } from '../api';
import { USER_STORAGE } from '../constants';
import { Account, UserInfo } from '../types/types';

interface UserContextProps {
  authUser?: any;
  setAuthUser: Dispatch<any>;
  userInfo?: UserInfo;
  setUserInfo: Dispatch<any>;
  loading: boolean;
  orgAccounts: Account[];
  canUseWebApp: boolean;
  setOrgAccounts: Dispatch<any>;
}

export const UserContext = createContext<UserContextProps>({
  authUser: undefined,
  setAuthUser: () => {},
  userInfo: undefined,
  setUserInfo: () => {},
  loading: true,
  orgAccounts: [],
  canUseWebApp: false,
  setOrgAccounts: () => {},
});

const getStoredSession = () => {
  const storedUser = sessionStorage.getItem(USER_STORAGE);
  if (storedUser && storedUser !== 'undefined') {
    const sess = JSON.parse(storedUser);
    const user = sess;
    if (user) {
      return {
        userInfo: user,
        authUser: sess,
      };
    }
  } else {
    return {
      userInfo: {},
      authUser: {},
    };
  }
};

interface UserContextProviderProps {
  userVal?: any;
  authVal?: any;
  children: React.ReactNode;
}
export const UserContextProvider = ({ children, userVal, authVal }: UserContextProviderProps) => {
  const [authUser, setAuthUser] = useState(authVal ? authVal : getStoredSession()?.authUser);
  const [userInfo, setUserInfo] = useState(userVal ? userVal : getStoredSession()?.userInfo);
  const [orgAccounts, setOrgAccounts] = useState<Account[]>([]);

  const canUseWebApp =
    userInfo?.user?.role?.type === 'ADMIN' ||
    userInfo?.user?.role?.type === 'EC' ||
    userInfo?.user?.role?.type === 'VIEWER';

  const getAccounts = useCallback(() => {
    return fetchAccounts().then((r) => r);
  }, []);

  useEffect(() => {
    if (userInfo?.user?.organization?.organizationId) {
      getAccounts().then((r) => setOrgAccounts((state) => [...state, ...(r || [])]));
    }
  }, [getAccounts, userInfo]);

  const value = { authUser, setAuthUser, userInfo, setUserInfo, loading: true, orgAccounts, canUseWebApp, setOrgAccounts };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useContext must be used within a UserContext');
  }
  return context;
};
