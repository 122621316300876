import React from 'react';

interface VideoCardProps {
  title: string;
  thumbnail?: string;
  duration?: string;
  onClick?: () => void;
}

const VideoCard = ({ title, thumbnail, duration, onClick = () => {} }: VideoCardProps) => {
  return (
    <div
      onClick={onClick}
      className=" m-2 flex w-full max-w-xs cursor-pointer flex-col overflow-hidden rounded-xl border border-gray-200 shadow-md transition-all hover:scale-[101%] hover:shadow-lg"
    >
      {thumbnail ? (
        <img className="aspect-video object-cover" src={thumbnail} alt="video" />
      ) : (
        <div className="aspect-video bg-gray-90" />
      )}
      <div className="p-4">
        <h3 className="text-lg font-semibold text-primary-800">{title}</h3>
        <p className="text-gray-500">{duration}</p>
      </div>
    </div>
  );
};

export default VideoCard;
