import { getHeaders, host } from '.';

export const fetchAccounts = async () => {
  const headers = await getHeaders();

  return fetch(host + `/accounts`, {
    headers,
    method: 'GET',
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.error) return [];
      return data;
    })
    .catch((e) => console.log('Error fetching accounts', e));
};

export const createAccount = async ({ name, code, owner, secondaryOwner, organization }) => {
  const headers = await getHeaders();

  return fetch(host + `/accounts`, {
    headers,
    method: 'POST',
    body: JSON.stringify({
      name,
      code,
      owner,
      secondaryOwner,
      organization,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((e) => console.log('Error updating account', e));
};

export const updateAccount = async ({ accountId, id, name, code, owner, secondaryOwner, organization }) => {
  const headers = await getHeaders();

  return fetch(host + `/accounts/${accountId}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify({
      accountId,
      id,
      name,
      code,
      owner,
      secondaryOwner: secondaryOwner.userId ? { userId: secondaryOwner.userId } : null,
      organization,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((e) => console.log('Error updating account', e));
};
