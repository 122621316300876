import Lottie from 'react-lottie';
import spinner from '../assets/lotties/spinner-blue.json';

interface SpinnerProps {
  size?: number;
}

const Spinner = ({ size = 50 }: SpinnerProps) => {
  const spinnerAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: spinner,
    rendererSettings: {
      scaleMode: 'contain',
    },
  };

  return (
    <Lottie
      options={spinnerAnimationOptions}
      style={{
        height: size,
      }}
    />
  );
};

export default Spinner;
