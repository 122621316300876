import { AssetInventoryStatus, AssetStatus, CheckoutStatus, InventoryStatus } from '../types/types';
import api from './api';

export const getActiveInventory = async (id: string) => {
  try {
    const { data } = await api.get(`/users/${id}/inventoryControl/active`);
    return data;
  } catch (e) {
    console.log('Error fetching active inventory: ', e);
  }
};

export const fetchInventory = async (id: string) => {
  try {
    const { data } = await api.get(`/inventoryControl/${id}`);
    return data;
  } catch (e) {
    console.log('Error fetching inventory: ', e);
  }
};

export const fetchInventoryAssets = async (
  id: string,
  payload?: {
    sort?: string[];
    size?: number;
    page?: number;
  },
) => {
  const { sort, size, page } = payload || {};
  const params = new URLSearchParams();
  if (sort) params.append('sort', sort.join(','));
  if (size) params.append('size', size.toString());
  if (page) params.append('page', page.toString());
  try {
    const { data } = await api.get(`/inventoryControl/${id}/assets/all?` + params.toString());
    return data;
  } catch (e) {
    console.log('Error fetching inventory: ', e);
  }
};

export const searchInventory = async (
  organizationId: number,
  payload: {
    accountName?: string;
    accountIds?: number[];
    inventoryControlName?: string;
    startDate?: string;
    endDate?: string;
    status?: InventoryStatus;
    sort?: string[];
    size?: number;
    page?: number;
  },
) => {
  const { accountName, accountIds, inventoryControlName, startDate, endDate, status, sort, size, page } = payload;

  const params = new URLSearchParams();
  if (accountName) params.append('accountName', accountName);
  if (accountIds) params.append('accountIds', accountIds.join(','));
  if (inventoryControlName) params.append('inventoryControlName', inventoryControlName);
  if (startDate) params.append('startDate', startDate);
  if (endDate) params.append('endDate', endDate);
  if (status) params.append('status', status);
  if (sort) params.append('sort', sort.join(','));
  if (size) params.append('size', size.toString());
  if (page) params.append('page', page.toString());

  try {
    const { data } = await api.get(`/inventoryControl/filters?${params.toString()}`);
    return data;
  } catch (e) {
    console.log('Error occurred searching inventory: ', e);
  }
};

export type AddInventory = {
  inventoryName: string;
  inventoryCheckedOut: boolean;
  startDate: string;
  endDate: string;
  users: number[];
  percentageToScan: number;
  assetsSearch: {
    accountIds: number[];
    assetName?: string | null;
    assetType?: string | null;
    status?: AssetStatus;
    checkoutStatus?: CheckoutStatus;
    inventoryStatus?: AssetInventoryStatus;
    buildingName?: string | null;
    buildingNames?: string[];
    buildingId?: number;
    hasMaintenanceNote?: boolean;
    manufacturerName?: string | null;
    roomName?: string | null;
    maintenance?: boolean;
    missing?: boolean;
    pilferable?: 'YES' | 'NO';
  };
};

export const addInventory = async (payload: AddInventory) => {
  try {
    const { data } = await api.post(`/inventoryControl`, payload);

    return data;
  } catch (e) {
    console.log('Error adding inventory: ', e);
  }
};

type UpdateInventoty = {
  inventoryCheckedOut: boolean;
  startDate: string;
  endDate: string;
  percentageToScan: number;
  assetsSearch: {
    accountIds: number[];
    assetName?: string;
    assetType?: string;
    status?: AssetStatus;
    buildingNames?: string[];
    buildingId?: number;
  };
};

export const updateInventoryControl = async (id: string, payload: UpdateInventoty) => {
  try {
    const { data } = await api.patch(`/inventoryControl/${id}/settings`, payload);
    return data;
  } catch (e) {
    console.log('Error updating inventory: ', e);
  }
};

export const updateInventoryControlName = async (id: number, name: string) => {
  try {
    const { data } = await api.patch(`/inventoryControl/${id}/name?name=${encodeURIComponent(name)}`);
    return data;
  } catch (e) {
    console.log('Error updating inventory name: ', e);
  }
};

export const inviteUsersToInventoryControl = async (id: number, users: string[]) => {
  try {
    const { data } = await api.patch(`/inventoryControl/${id}/invite?users=${users}`);

    if (data.inventoryControlId) {
      return data;
    } else {
      throw new Error('Error inviting user to inventory.', data.error);
    }
  } catch (e) {
    console.log('Error inviting user to inventory: ', e);
  }
};

export const uninviteUsersToInventoryControl = async (id: number, users: string[]) => {
  try {
    const { data } = await api.patch(`/inventoryControl/${id}/uninvite?users=${users}`);
    return data;
  } catch (e) {
    console.log('Error uninviting user to inventory: ', e);
  }
};

export const completeInventoryControl = async (id: number) => {
  try {
    const { data } = await api.patch(`/inventoryControl/${id}/complete`);
    return data;
  } catch (e) {
    console.log('Error completing inventory: ', e);
  }
};
