const TermsAndConditions = () => {
  return (
    <div className="mx-auto max-w-[75ch] p-5 font-medium text-gray-600 md:p-10">
      <h1 className="text-2xl font-bold text-gray-800">Terms Of Use</h1>
      <span className="text-sm font-bold">Last updated June 4, 2023</span>
      <br /> <br />
      <b>AGREEMENT TO OUR LEGAL TERMS</b>
      <br />
      <br />
      <b>INTRODUCTION</b>
      <br />
      <p>
        We are Sleepy Owl Solutions, Inc. ("Company," "we," "us," "our"), a Colorado corporation registered in Colorado,
        United States at 3900 E. Mexico Ave, Ste 300 Denver, CO 80210.
        <br />
        <br />
        We operate the mobile application Owly (the "App"), which allows its users to check, track and audit inventory
        and assets (the "Services"). In some places, this agreement refers to Services separately; however, references
        to accessing and using the App should be interpreted to include the access and use of both the App and the
        Services, where the context requires both.
        <br />
        <br />
        These Terms of Use ("Terms") constitute a legally binding agreement made between you, whether personally or on
        behalf of an entity ("you"), and the Company, and apply any time you access and use the App.
        <br />
        <br />
        These Terms also include any End User License Agreements ("EULA") and our Privacy Policy as updated from
        time-to-time, which are expressly incorporated into and made part of these Terms (collectively, the "Legal
        Terms"). You agree that by using the App, you have read, understood, and agreed to be bound by these of these
        Legal Terms.
        <br />
        <br />
        IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE APP AND YOU
        MUST DISCONTINUE USE IMMEDIATELY.
        <br />
        <br />
        We will provide you with prior notice of any scheduled changes to the App you are using. The modified Legal
        Terms will become effective upon posting or notifying you by info@sleepyowl.com, as stated in the email message.
        <br /> <br />
        By continuing to use the App after the effective date of any changes, you agree to be bound by the modified
        terms.
        <br /> <br />
        The App are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to
        use the App or register for the Services.
        <br /> <br />
        We recommend that you print a copy of these Legal Terms for your records.{' '}
      </p>
      <br />
      <b>1. OUR SERVICES</b>
      <p>
        The information provided when using the App is not intended for distribution to or use by any person or entity
        in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which
        would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons
        who choose to access the Services from other locations do so on their own initiative and are solely responsible
        for compliance with local laws, if and to the extent local laws are applicable.
      </p>
      <br />
      <b>2. INTELLECTUAL PROPERTY RIGHTS</b>
      <p>
        a. Trademark and Copyright. We are the owner or the licensee of all intellectual property rights in our App and
        the Services, including all source code, databases, functionality, software, website designs, audio, video,
        text, photographs, and graphics in the Services (collectively, the "Content"), as well as the trademarks,
        service marks, and logos contained therein (the "Marks").
        <br /> <br />
        Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property
        rights and unfair competition laws) and treaties in the United States and around the world.
        <br /> <br />
        b. No Warranty and License. The Content and Marks are provided in or through the Services "AS IS" for your
        internal business purpose only. Your use of our App and Services is subject to your compliance with these Legal
        Terms, including the "PROHIBITED ACTIVITIES" section below, we grant you a non-exclusive, non-transferable,
        revocable license to: use the App, access the Services; and download or print a copy of any portion of the
        Content to which you have properly gained access solely for your internal business purpose.
        <br /> <br />
        Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or
        Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded,
        translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose
        whatsoever, without our express prior written permission.
        <br /> <br />
        c. Permission and Attribution. If you wish to make any use of the Services, Content, or Marks other than as set
        out in this section or elsewhere in our Legal Terms, please address your request to: info@sleepyowl.tech If we
        ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you
        must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or
        proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
        <br /> <br />
        d. Rights Reserved. We reserve all rights not expressly granted to you in and to the Services, Content, and
        Marks. Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and
        your right to use our Services will terminate immediately.
        <br /> <br />
        e. Your Contributions. Please review this section and the "PROHIBITED ACTIVITIES" section carefully prior to
        using our Services to understand the rights you give us and the obligations you have when you post or upload any
        content through the Services.
        <br />
        <li>
          Contributions. The Services may invite you to chat, contribute to, or participate in blogs, message boards,
          online forums, and other functionality during which you may create, submit, post, display, transmit, publish,
          distribute, or broadcast content and materials to us or through the Services, including but not limited to
          text, writings, video, audio, photographs, music, graphics, comments, reviews, rating suggestions, personal
          information, or other material ("Contributions"). You understand that Contributions may be viewable by other
          users of the Services. By posting any Contributions, you grant us an unrestricted, unlimited, irrevocable,
          perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to: use, copy,
          reproduce, distribute, sell, resell, publish, broadcast, retitle, store, publicly perform, publicly display,
          reformat, translate, excerpt (in whole or in part), and exploit your Contributions (including, without
          limitation, your image, name, and voice) for the purposes of (A) providing the Services, and (B) for our use
          in demonstrating and marketing the App.
          <br /> <br />
          Our use and distribution may occur in any media formats and through any media channels. This license includes
          our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service
          marks, trade names, logos, and personal and commercial images you provide.
        </li>
        <br />
        <li>
          You Warrant. By posting Contributions through any part of the Services or making Contributions accessible
          through the Services by linking your account through the Services to any of your social networking accounts,
          you:
          <br /> <br />
        </li>
        <ol>
          <li>
            confirm that you have read and agree with our "PROHIBITED ACTIVITIES" and will not post, send, publish,
            upload, or transmit through the Services any Contribution that is illegal, harassing, hateful, harmful,
            defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually
            explicit, false, inaccurate, deceitful, or misleading; to the extent permissible by applicable law, waive
            any and all moral rights to any such Contribution;
          </li>

          <li>
            warrant that any Contributions are original to you or that you have the necessary rights and licenses to
            submit such Contributions and that you have full authority to grant us the above-mentioned rights in
            relation to your Contributions;
          </li>

          <li>
            warrant and represent that your Contributions do not constitute the unauthorized disclosure of confidential
            information and do not and will not infringe on the proprietary or intellectual property rights of any third
            party; and
          </li>

          <li>
            confirm that your contributions are not false, inaccurate or misleading and do not constitute unsolicited or
            unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or
            other forms of solicitation.
          </li>
        </ol>
        We are not liable for any statements or representations in your Contributions provided by you through the App or
        in any area on the Services. You are solely responsible for your Contributions, and you expressly agree to
        reimburse us for any and all losses that we may suffer because of your breach of (X) this section, (Y) any third
        party's intellectual property rights, or (Z) applicable law.
        <br /> <br />
        f. Removal or Editing of Your Content. We take have no obligation to monitor any Contributions and shall have
        the right, in our sole and absolute discretion to remove or edit any Contributions at any time without notice,
        if in our reasonable opinion we consider such Contributions harmful or in breach of these Legal Terms. If we
        remove or edit any such Contributions, we may also suspend or disable your account and report you to the
        authorities.
      </p>
      <br />
      <b>3. USER REPRESENTATIONS</b>
      <p>
        By using the App and our Services, you represent and warrant that:
        <br />
        <ol>
          <li>all registration information you submit will be true, accurate, current, and complete;</li>
          <li>
            you will maintain the accuracy of such information and promptly update such registration information as
            necessary;
          </li>
          <li>you have the legal capacity, and you agree to comply with these Legal Terms;</li>
          <li>you are not a minor in the jurisdiction in which you reside;</li>
          <li>
            you will not access the Services through automated or non-human means, whether through a bot, script or
            otherwise;
          </li>
          <li>you will not use the Services for any illegal or unauthorized purpose; and</li>
          <li>your use of the Services will not violate any applicable law or regulation.</li>
        </ol>
        If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to
        suspend or terminate your account and refuse any and all current or future use of the Services (or any portion
        thereof).
      </p>
      <br />
      <b>4. USER REGISTRATION</b>
      <p>
        You may be required to register to use the Services. You agree to keep your password confidential and will be
        responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a
        username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or
        otherwise objectionable.
      </p>
      <br />
      <b>5. SOFTWARE</b>
      <p>
        We may include software for use in connection with our Services. If such software is accompanied by a EULA, the
        terms of the EULA will govern your use of the software. If such software is not accompanied by a EULA, then we
        grant to you a non-exclusive, revocable, personal, and non-transferable license to use such software solely in
        connection with our services and in accordance with these Legal Terms. Any software and any related
        documentation is provided "AS IS" without warranty of any kind, either express or implied, including, without
        limitation, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
        You accept any and all risk arising out of use or performance of any software. You may not reproduce or
        redistribute any software except in accordance with the EULA or these Legal Terms.
      </p>
      <br />
      <b>6. PROHIBITED ACTIVITIES</b>
      <p>
        You may not access or use the App or the Services for any purpose other than that for which we make the Services
        available. The Services may not be used in connection with any commercial endeavors except those that are
        specifically endorsed or approved by us. As a user of the App and the Services, you agree not to:
      </p>
      <ol>
        <li>
          Systematically retrieve data or other content from the Services to create or compile, directly or indirectly,
          a collection, compilation, database, or directory without written permission from us.
        </li>
        <li>
          Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account
          information such as user passwords.
        </li>
        <li>
          Circumvent, disable, or otherwise interfere with security-related features of the Services, including features
          that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services
          and/or the Content contained therein.
        </li>
        <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
        <li>Use any information obtained from the Services in order to harass, abuse, or harm another person.</li>
        <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
        <li>Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
        <li>Engage in unauthorized framing of or linking to the Services.</li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including
          excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with
          any party's uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or
          interferes with the use, features, functions, operation, or maintenance of App or the Services.
        </li>
        <li>
          Engage in any automated use of the system, such as using scripts to send comments or messages, or using any
          data mining, robots, or similar data gathering and extraction tools.
        </li>
        <li>Delete the copyright or other proprietary rights notice from any Content.</li>
        <li>Attempt to impersonate another user or person or use the username of another user.</li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active
          information collection or transmission mechanism, including without limitation, clear graphics interchange
          formats ( "gifs" ), 1×1pixels, web bugs, cookies, or other similar devices (sometimes referred to as "spyware"
          or "passive collection mechanisms" or "pcms").
        </li>
        <li>
          Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to
          the Services.
        </li>
        <li>
          Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the
          Services to you.
        </li>
        <li>
          Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any
          portion of the Services.
        </li>
        <li>
          Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other
          code.
        </li>
        <li>
          Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the
          software comprising or in any way making up a part of the Services.
        </li>
        <li>
          Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or
          distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or
          offline reader that accesses the Services, or use or launch any unauthorized script or other software.
        </li>
        <li>
          Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by
          electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated
          means or under false pretenses.
        </li>
        <li>
          Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for
          any revenue-generating endeavor or commercial enterprise.
        </li>
      </ol>
      <br />
      <b>7. MOBILE APPLICATION LICENSE</b>
      <p>
        Use License: If you access the Services via the App, then we grant you a revocable, non-exclusive,
        non-transferable, limited right to install and use the App on wireless electronic devices owned or controlled by
        you, and to access and use the App on such devices strictly in accordance with the terms and conditions of this
        mobile application license contained in these Legal Terms.
      </p>
      <ol>
        <li>
          Prohibited App Uses. You shall not:
          <ol>
            <li>
              except as permitted by applicable law, decompile, reverse engineer, disassemble, attempt to derive the
              source code of, or decrypt the App;
            </li>
            <li>
              make any modification, adaptation, improvement, enhancement, translation, or derivative work from the App;
            </li>
            <li>
              violate any applicable laws, rules, or regulations in connection with your access or use of the App;
            </li>
            <li>
              remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted
              by us or the licensors of the App;
            </li>
            <li>
              use the App for any revenue-generating endeavor, commercial enterprise, or other purpose for which it is
              not designed or intended;
            </li>
            <li>
              make the App available over a network or other environment permitting access or use by multiple devices or
              users at the same time;
            </li>
            <li>
              use the App for creating a product, service, or software that is, directly or indirectly, competitive with
              or in any way a substitute for the App;
            </li>
            <li>
              use the App to send automated queries to any website or to send any unsolicited commercial email; or
            </li>
            <li>
              use any proprietary information or any of our interfaces or our other intellectual property in the design,
              development, manufacture, licensing, or distribution of any applications, accessories, or devices for use
              with the App.
            </li>
          </ol>
        </li>
        <br />
        <li>
          Apple and Android Devices. The following terms apply when you use the App obtained from either the Apple Store
          or Google Play (each an "App Distributor") to access the Services:
        </li>
        <ol>
          <li>
            the license granted to you for our App is limited to a non-transferable license to use the application on a
            device that utilizes the Apple iOS or Android operating systems, as applicable, and in accordance with the
            usage rules set forth in the applicable App Distributor's terms of service;
          </li>
          <li>
            we are responsible for providing any maintenance and support services with respect to the App as specified
            in the terms and conditions of this mobile application license contained in these Legal Terms or as
            otherwise required under applicable law, and you acknowledge that each App Distributor has no obligation
            whatsoever to furnish any maintenance and support services with respect to the App;
          </li>
          <li>
            in the event of any failure of the App to conform to any applicable warranty, you may notify the applicable
            App Distributor, and the App Distributor, in accordance with its terms and policies, may refund the purchase
            price, if any, paid forte App, and to the maximum extent permitted by applicable law, the App Distributor
            will have no other warranty obligation whatsoever with respect to the App;
          </li>
          <li>
            you represent and warrant that:
            <ol>
              <li>
                you are not located in a country that is subject to a US government embargo, or that has been designated
                by the US government as a "terrorist supporting" country and
              </li>
              <li>you are not listed on any US government list of prohibited or restricted parties;</li>
            </ol>
          </li>
          <li>
            you must comply with applicable third-party terms of agreement when using the App, e.g. , if you have a VoIP
            application, then you must not be in violation of their wireless data service agreement when using the App;
            and
          </li>
          <li>
            you acknowledge and agree that the App Distributors are third-party beneficiaries of the terms and
            conditions in this mobile application license contained in these Legal Terms, and that each App Distributor
            will have the right (and will be deemed to have accepted the right) to enforce the terms and conditions in
            this mobile application license contained in these Legal Terms against you as a third-party beneficiary
            thereof.
          </li>
        </ol>
      </ol>
      <br />
      <b>8. SERVICES MANAGEMENT</b>
      <p>
        We reserve the right, but not the obligation, to:
        <ol>
          <li>monitor the Services for violations of these Legal Terms;</li>
          <li>
            take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal
            Terms, including without limitation, reporting such user to law enforcement authorities;
          </li>
          <li>
            in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or
            disable (to the extent technologically feasible) any of your Contributions or any portion thereof;
          </li>
          <li>
            in our sole discretion and without limitation, notice, or liability, to remove from the Services or
            otherwise disable all files and content that are excessive in size or are in any way burdensome to our
            systems; and
          </li>
          <li>
            otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the
            proper functioning of the Services.
          </li>
        </ol>
      </p>
      <br />
      <b>9. PRIVACY POLICY</b>
      <p>
        We care about data privacy and security. Please review our Privacy Policy located at:{' '}
        <a href="https://webapp.sleepyowl.tech/privacy">https://webapp.sleepyowl.tech/privacy</a>. By using the
        Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms. Please be
        advised the Services are hosted in the United States . If you access the Services from any other region of the
        world with laws or other requirements governing personal data collection, use, or disclosure that differ from
        applicable laws in the United States ,then through your continued use of the Services, you are transferring your
        data to the United States , and you expressly consent to have your data transferred to and processed in the
        United States.
      </p>
      <br />
      <b>10. DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY</b>
      <ol>
        <li>
          Notifications. We respect the intellectual property rights of others. If you believe that any material
          available on or through the Services infringes upon any copyright you own or control, please immediately
          notify us using the contact information provided below (a "Notification"). A copy of your Notification will be
          sent to the person who posted or stored the material addressed in the Notification. Please be advised that
          pursuant to federal law you may be held liable for damages if you make material misrepresentations in a
          Notification. Thus, if you are not sure that material located on or linked to by the Services infringes your
          copyright, you should consider first contacting an attorney.
          <br /> <br />
          All Notifications should meet the requirements of DMCA 17 U.S.C. § 512(c)(3) and include the following
          information:
        </li>
        <ol>
          <li>
            A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
            right that is allegedly infringed;
          </li>
          <li>
            identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works on
            the Services are covered by the Notification, a representative list of such works on the Services;
          </li>
          <li>
            identification of the material that is claimed to be infringing or to be the subject of infringing activity
            and that is to be removed or access to which is to be disabled, and information reasonably sufficient to
            permit us to locate the material;
          </li>
          <li>
            information reasonably sufficient to permit us to contact the complaining party, such as an address,
            telephone number, and, if available, an email address at which the complaining party may be contacted;
          </li>
          <li>
            a statement that the complaining party has a good faith belief that use of the material in the manner
            complained of is not authorized by the copyright owner, its agent, or the law; and
          </li>
          <li>
            a statement that the information in the notification is accurate, and under penalty of perjury, that the
            complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly
            infringed upon.
          </li>
        </ol>
        <br />
        <li>
          Counter Notification. If you believe your own copyrighted material has been removed from the Services as a
          result of a mistake or misidentification, you may submit a written counternotification to us using the contact
          information provided below (a "Counter Notification" ). To be an effective Counter Notification under the
          DMCA, your Counter Notification must include substantially the following:
          <li>
            identification of the material that has been removed or disabled and the location at which the material
            appeared before it was removed or disabled;
          </li>
          <li>
            a statement that you consent to the jurisdiction of the Federal District Court in which your address is
            located, or if your address is outside the United States, for any judicial district in which we are located;
          </li>
          <li>
            a statement that you will accept service of process from the party that filed the Notification or the
            party's agent;
          </li>
          <li>your name, address, and telephone number;</li>
          <li>
            a statement under penalty of perjury that you have a good faith belief that the material in question was
            removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled;
            and
          </li>
          <li>your physical or electronic signature.</li>
        </li>
      </ol>
      If you send us a valid, written Counter Notification meeting the requirements described above, we will restore
      your removed or disabled material, unless we first receive notice from the party filing the Notification informing
      us that such party has filed a court action to restrain you from engaging in infringing activity related to the
      material in question. Filing a false Counter Notification constitutes perjury. Please note that if you materially
      misrepresent that the disabled or removed content was removed by mistake or misidentification, you may be liable
      for damages, including costs and attorney's fees.
      <br /> <br />
      <p>
        Registered Agent Contact information: Summit Law Solutions, LLC, 3900 E Mexico Ave, Ste 300, Denver, Colorado
        80210 USA.
      </p>
      <br />
      <b>11. TERM AND TERMINATION</b>
      <p>
        These Legal Terms shall remain in full force and effect while you use the Services.
        <br /> <br />
        WITHOUT liMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SolE DISCRETION AND
        WITHOUT NOTICE OR liABIliTY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES),
        TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT liMITATION FOR BREACH OF ANY REPRESENTATION,
        WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE
        YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED
        AT ANYTIME, WITHOUT WARNING, IN OUR SolE DISCRETION.
        <br /> <br />
        If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new
        account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on
        behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take
        appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
      </p>
      <br />
      <b>12. MODIFICATIONS AND INTERRUPTIONS</b>
      <p>
        We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at
        our sole discretion without notice. However, we have no obligation to update any information on our Services. We
        will not be liable to you or any third party for any modification, price change, suspension, or discontinuance
        of the Services.
        <br /> <br />
        We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other
        problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors.
        We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any
        time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss,
        damage, or inconvenience caused by your inability to access or use the Services during any downtime or
        discontinuance of the Services. Nothing in these Legal Terms will be construed to obligate us to maintain and
        support the Services or to supply any corrections, updates, or releases in connection therewith.
      </p>
      <br />
      <b>13. GOVERNING LAW</b>
      <p>
        These Legal Terms and your use of the Services are governed by and construed in accordance with the laws of the
        State of Colorado applicable to agreements made and to be entirely performed within the State of Colorado ,
        without regard to its conflict of law principles.
      </p>
      <br />
      <b>14. DISPUTE RESolUTION</b>
      <p>
        <ol>
          <li>
            Informal Negotiations. To expedite resolution and control the cost of any dispute, controversy, or claim
            related to these Legal Terms (each a "Dispute" and collectively, the "Disputes") brought by either you or us
            (individually, a "Party" and collectively, the "Parties"), the Parties agree to first attempt to negotiate
            any Dispute (except those Disputes expressly provided below) informally for at least thirty (30) days before
            initiating arbitration. Such informal negotiations commence upon written notice from one Party to the other
            Party.
          </li>
          <br />
          <li>
            Binding Arbitration. If the Parties are unable to resolve a Dispute through informal negotiations, the
            Dispute (except those Disputes expressly excluded below) will be finally and exclusively resolved by binding
            arbitration.
            <br /> <br />
            YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL.
            <br /> <br />
            The arbitration shall be commenced and conducted under the Commercial Arbitration Rules of the American
            Arbitration Association ("AAA") and, where appropriate, the AAA's Supplementary Procedures for Consumer
            Related Disputes ("AAA Consumer Rules"), both of which are available at the AAA website www.adr.org . Your
            arbitration fees and your share of arbitrator compensation shall be governed by the AAA Consumer Rules and,
            where appropriate, limited by the AAA Consumer Rules. If such costs are determined by the arbitrator to be
            excessive, we will pay all arbitration fees and expenses.
            <br /> <br />
            The arbitration may be conducted in person, through the submission of documents, by phone, or online. The
            arbitrator will make a decision in writing but need not provide a statement of reasons unless requested by
            either Party. The arbitrator must follow applicable law, and any award may be challenged if the arbitrator
            fails to do so. Except where otherwise required by the applicable AAA rules or applicable law, the
            arbitration will take place in Denver, Colorado. To the extent allowable by applicable law, all
            negotiations, proceedings, findings and awards shall remain confidential.
            <br /> <br />
            Except as otherwise provided herein, the Parties may litigate in court to compel arbitration, stay
            proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the award entered by
            the arbitrator. If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute shall be
            commenced or prosecuted in the state and federal courts located in Denver, Colorado, and the Parties hereby
            consent to, and waive all defenses of lack of personal jurisdiction, and forum non conveniens with respect
            to venue and jurisdiction in such state and federal courts.
            <br /> <br />
            Application of the United Nations Convention on Contracts for the International Sale of Goods and the
            Uniform Computer Information Transaction Act (UCITA) are excluded from these Legal Terms.
          </li>
          <br />
          <li>
            Time to Bring a Claim. In no event shall any Dispute brought by either Party related in any way to the
            Services be commenced more than one (1) year after the cause of action arose.
          </li>
          <br />
          <li>
            Class Action Waiver. The Parties agree that any arbitration shall be limited to the Dispute between the
            Parties individually. To the full extent permitted by law,
            <ol>
              <li>no arbitration shall be joined with any other proceeding;</li>
              <li>
                there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize
                class action procedures; and
              </li>
              <li>
                there is no right or authority for any Dispute to be brought in a purported representative capacity on
                behalf of the general public or any other persons.
              </li>
            </ol>
          </li>
          <br />
          <li>
            Exceptions to Informal Negotiations and Arbitration. The Parties agree that the following Disputes are not
            subject to the above provisions concerning informal negotiations binding arbitration:
            <ol>
              <li>
                any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual
                property rights of a Party;
              </li>
              <li>
                any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or
                unauthorized use; and
              </li>
              <li>any claim for injunctive relief.</li>
            </ol>
          </li>
          <br />
          <li>
            Severability. If any part of this provision is found to be illegal or unenforceable, then neither Party will
            elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or
            unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts
            listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.
          </li>
        </ol>
      </p>
      <br />
      <b>15. CORRECTIONS</b>
      <p>
        There may be information on the Services that contains typographical errors, inaccuracies, or omissions,
        including descriptions, pricing, availability, and various other information. We reserve the right to correct
        any errors, inaccuracies, or omissions and to change or update the information on the Services at any time,
        without prior notice.
      </p>
      <br />
      <b>16. DISCLAIMER</b>
      <p>
        THE APP AND THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE
        SERVICES WILL BE AT YOUR SolE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS
        OR IMPliED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT liMITATION, THE IMPliED
        WARRANTIES OF MERCHANTABIliTY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR
        MOBILE APPLICATIONS liNKED TO THE SERVICES AND WE WILL ASSUME NO liABIliTY OR RESPONSIBIliTY FOR ANY (a) ERRORS,
        MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (b) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
        WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (c) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
        SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (d) ANY
        INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (e) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
        liKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (f) ANY ERRORS OR OMISSIONS
        IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
        CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES.
        <br /> <br />
        WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBIliTY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED
        BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERliNKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN
        ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
        TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT
        OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
        WHERE APPROPRIATE.
      </p>
      <br />
      <b>17. liMITATIONS OF liABIliTY</b>
      <p>
        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE liABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
        INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST
        REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF
        THE POSSIBIliTY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR liABIliTY TO YOU
        FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE liMITED TO THE AMOUNT
        PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING.
        <br /> <br />
        CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW liMITATIONS ON IMPliED WARRANTIES OR THE EXCLUSION OR
        liMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR liMITATIONS
        MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
      </p>
      <br />
      <b>18. INDEMNIFICATION</b>
      <p>
        You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our
        respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or
        demand, including reasonable attorneys' fees and expenses, made by any third party due to or arising out of:
        <ol>
          <li>your Contributions;</li>
          <li>your use of the Services;</li>
          <li>your breach of these Legal Terms;</li>
          <li>any breach of your representations and warranties set forth in these Legal Terms;</li>
          <li>
            your violation of the rights of a third party, including but not limited to intellectual property rights; or
          </li>
          <li>any overt harmful act toward any other user of the Services with whom you connected via the Services.</li>
        </ol>
        Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and
        control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense,
        with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or
        proceeding which is subject to this indemnification upon becoming aware of it.
      </p>
      <br />
      <b>19. USER DATA</b>
      <p>
        We will maintain certain data that you transmit to the Services for the purpose of managing the performance of
        the Services, as well as data relating to your use of the Services. Although we perform regular routine backups
        of data, you are solely responsible for all data that you transmit or that relates to any activity you have
        undertaken using the Services. You agree that we shall have no liability to you for any loss or corruption of
        any such data, and you hereby waive any right of action against us arising from any such loss or corruption of
        such data.
      </p>
      <br />
      <b>20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS,AND SIGNATURES</b>
      <p>
        Visiting the Services, sending us emails, and completing online forms constitutes electronic communications. You
        consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other
        communications we provide to you electronically, via email and on the Services, satisfy any legal requirement
        that such communication be in writing.
        <br /> <br />
        YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC
        DEliVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES.
        <br /> <br />
        You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in
        any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to
        payments or the granting of credits by any means other than electronic means.
      </p>
      <br />
      <b>21. CAliFORNIA USERS AND RESIDENTS</b>
      <p>
        If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the
        Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market
        Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.
      </p>
      <br />
      <b>22. MISCELLANEOUS</b>
      <p>
        These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services
        constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any
        right or provision of these Legal Terms shall not operate as a waiver of such right or provision. These Legal
        Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations
        to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act
        caused by any cause beyond our reasonable control. If any provision or part of a provision of these Legal Terms
        is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed
        severable from these Legal Terms and does not affect the validity and enforceability of any remaining
        provisions. There is no joint venture, partnership, employment or agency relationship created between you and us
        as a result of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed
        against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the
        electronic form of these Legal Terms and the lack of signing by the parties hereto to execute these Legal Terms.
      </p>
      <br />
      <b>23. CONTACT INFORMATION</b>
      <p>
        In order to resolve a complaint regarding the Services or to receive further information regarding use of the
        Services, please contact us at:
        <br /> <br />
        info@sleepyowl.tech
        <br /> <br />
        Service of process may be sent to our registered agent:
        <br />
        Summit Law Solutions, LLC, 3900 E Mexico Ave, Ste 300, Denver, Colorado 80210 USA
        <br />
      </p>
    </div>
  );
};
export default TermsAndConditions;
