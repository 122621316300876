import { ReactNode } from 'react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

interface CardProps {
  children: ReactNode;
  title?: string;
  headerComponent?: ReactNode;
  footerComponent?: ReactNode;
  className?: string;
}
const Card = ({ children, title, headerComponent, footerComponent, className }: CardProps) => {
  const hasHeader = title || headerComponent;

  return (
    <div
      className={twMerge(
        classNames(
          'flex flex-col items-start justify-start rounded-lg border border-gray-100 bg-white shadow-lg shadow-gray-100/20',
          className,
        ),
      )}
    >
      <div className="flex w-full flex-1 flex-col gap-4 p-4">
        {hasHeader && <Header title={title} component={headerComponent} />}
        {children}
      </div>
      {footerComponent && <Footer>{footerComponent}</Footer>}
    </div>
  );
};

interface HeaderProps {
  title?: string;
  component?: ReactNode;
}
const Header = ({ title, component }: HeaderProps) => {
  return (
    <div className="flex w-full items-center justify-between">
      {title && <h3 className="flex-1 text-xl font-bold text-primary-800">{title}</h3>}
      <div className="flex-0">{component}</div>
    </div>
  );
};

interface FooterProps {
  children?: ReactNode;
}
const Footer = ({ children }: FooterProps) => {
  return <div className="flex w-full items-center justify-between gap-2 bg-gray-90 p-4">{children}</div>;
};

interface PropertyProps {
  label: string;
  value?: string | number;
  children?: ReactNode;
}
const Property = ({ label, value, children }: PropertyProps) => {
  return (
    <div className="flex w-full flex-row items-center justify-between gap-10">
      <span className="min-w-[25%] text-sm font-bold text-primary-800">{label}</span>
      {value ? (
        <span className="break-all	text-end text-sm text-gray-800">{value}</span>
      ) : children ? (
        <>{children}</>
      ) : (
        '-'
      )}
    </div>
  );
};

Card.Header = Header;
Card.Footer = Footer;
Card.Property = Property;

export default Card;
