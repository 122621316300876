/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactNode, Suspense, useState } from 'react';
import authBackground from '../assets/images/auth-background.svg';
import { Outlet, useLocation } from 'react-router-dom';
import { useGreater } from '../hooks/useBreakpoints';
import { AuthContextProvider } from '../contexts/AuthContext';
import Modal from '../components/Modal';
import Legal from '../components/Legal';
import routes from '../constants/routes';

const DesktopLayout = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const [legalModalOpen, setLegalModalOpen] = useState(location.pathname === routes.legal ?? false);
  return (
    <>
      <div className="relative z-0 flex h-screen flex-col justify-center bg-primary-800">
        <img
          className="absolute right-0 top-0 -z-10 ml-auto h-screen overflow-hidden object-cover"
          src={authBackground}
          alt="background"
          draggable="false"
        />

        <div className="flex flex-row items-center gap-x-10">
          <div className="flex-1 text-white">
            <div className="mx-auto flex h-screen w-fit flex-col justify-center">
              <div className="flex flex-1 flex-col justify-center">
                <h1 className="text-splash-page-title">Sleepy Owl Solutions</h1>
                <span className="text-splash-page-subheader">Innovative solutions for airmen.</span>
              </div>
              <div className="mb-10 flex gap-x-6">
                <a
                  role="button"
                  className="font-normal text-white visited:text-white"
                  onClick={() => setLegalModalOpen(true)}
                >
                  Terms & Conditions
                </a>
                <span className="select-none">/</span>

                <a
                  role="button"
                  className="font-normal text-white visited:text-white"
                  onClick={() => setLegalModalOpen(true)}
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>

          <div className="flex-1">
            <div className="max-h-screen overflow-visible overflow-y-scroll py-10">{children}</div>
          </div>
        </div>
      </div>
      <Modal closeOnClickOutside open={legalModalOpen} onClose={() => setLegalModalOpen(false)}>
        <div className="max-w-[70ch]">
          <Legal />
        </div>
      </Modal>
    </>
  );
};

const AuthLayout = () => {
  const isDesktop = useGreater('lg');

  const Component = isDesktop ? (
    <DesktopLayout>
      <Suspense>
        <Outlet />
      </Suspense>
    </DesktopLayout>
  ) : (
    <Suspense>
      <Outlet />
    </Suspense>
  );

  return <AuthContextProvider>{Component}</AuthContextProvider>;
};

export default AuthLayout;
