import { CognitoUser } from '@aws-amplify/auth';
import React, { useState, ReactNode, createContext, useContext, Dispatch, SetStateAction } from 'react';

interface AuthContextType {
  user: CognitoUser | null;
  setUser: Dispatch<SetStateAction<CognitoUser | null>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);
export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [email, setEmail] = useState<string>('');

  return <AuthContext.Provider value={{ user, setUser, email, setEmail }}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthContext');
  }
  return context;
};
